<script setup lang="ts" generic="T extends { id: PropertyKey; label: string; [key: PropertyKey]: unknown; }">
import { animations, dragAndDrop, multiDrag, type ParentConfig, selections } from "@formkit/drag-and-drop";
import { onMounted, ref, type Ref, watch } from "vue";

import VdButton from "@/components/structures/VdButton/VdButton.vue";

type AvailableColumns = T[];
type SelectedColumns = T[];

const props = defineProps<{
  columns: AvailableColumns;
  modelValue: SelectedColumns;
}>();

const emit = defineEmits<{
  "update:model-value": [value: T[]];
}>();

const parentConfig: Partial<ParentConfig<T>> = {
  group: "custom-columns",
  dropZoneClass: "bg-primary",

  plugins: [
    multiDrag({
      plugins: [
        selections({
          selectedClass: "bg-primary selected-node",
        }),
      ],
      dropZoneClass: "bg-primary",
    }),
    animations(),
  ],
};

const currentSelection = ref<[AvailableColumns, SelectedColumns]>([
  props.columns.filter(item => !props.modelValue.map(column => column.id).includes(item.id)),
  props.modelValue,
]);

const availableParentRef = ref<HTMLElement>();
const availableColumns = ref<AvailableColumns>([]) as Ref<AvailableColumns>;

const selectedParentRef = ref();
const selectedColumns = ref<SelectedColumns>([]) as Ref<SelectedColumns>;

watch(
  currentSelection,
  ([availableColumnsValue, selectedColumnsValue]) => {
    availableColumns.value = availableColumnsValue as AvailableColumns;
    selectedColumns.value = selectedColumnsValue as SelectedColumns;
  },
  { immediate: true, deep: true },
);

onMounted(() => {
  if (!availableParentRef.value || !selectedParentRef.value) {
    return;
  }

  dragAndDrop({
    parent: availableParentRef.value,
    getValues: () => {
      return availableColumns.value;
    },
    setValues: (newValues) => {
      availableColumns.value = newValues;
    },
    config: { ...parentConfig },
  });

  dragAndDrop({
    parent: selectedParentRef.value,
    getValues: () => {
      return selectedColumns.value;
    },
    setValues: (newValues) => {
      selectedColumns.value = newValues;
    },
    config: { ...parentConfig },
  });
});

function onMoveToSelected() {
  const selectedAvailable = availableParentRef.value?.getElementsByClassName("selected-node");

  if (!selectedAvailable) {
    return;
  }

  if (selectedAvailable.length > 0) {
    const newSelectedColumns = [...selectedAvailable]
      .map(item => availableColumns.value.find(column => column.id === item.getAttribute("data-id")) ?? null)
      .filter(Boolean);

    selectedColumns.value = [...newSelectedColumns, ...selectedColumns.value];

    availableColumns.value = availableColumns.value.filter(
      item => !newSelectedColumns.map(column => column.id).includes(item.id),
    );
  }
}

function onMoveToAvailable() {
  const selectedSelected = selectedParentRef.value?.getElementsByClassName("selected-node");

  if (!selectedSelected) {
    return;
  }

  if (selectedSelected.length > 0) {
    const newAvailableColumns = [...selectedSelected]
      .map(item => selectedColumns.value.find(column => column.id === item.getAttribute("data-id")) ?? null)
      .filter(Boolean);

    availableColumns.value = [...newAvailableColumns, ...availableColumns.value];

    selectedColumns.value = selectedColumns.value.filter(
      item => !newAvailableColumns.map(column => column.id).includes(item.id),
    );
  }
}

function onMoveAllToSelected() {
  selectedColumns.value = [...availableColumns.value, ...selectedColumns.value];
  availableColumns.value = [];
}

function onMoveAllToAvailable() {
  availableColumns.value = [...selectedColumns.value, ...availableColumns.value];
  selectedColumns.value = [];
}

watch(
  () => selectedColumns.value,
  (newSelectedColumns) => {
    emit("update:model-value", [...newSelectedColumns]);
  },
);

const columnsPickerListItemClass = "mb-px flex cursor-pointer list-none items-center justify-between rounded-lg px-3 py-2";
const columnsPickerButtonClass = "pointer-events-auto h-8 w-4 cursor-pointer bg-white";
</script>

<template>
  <div class="columns-picker-component relative grid w-full grid-cols-2 overflow-auto rounded-lg">
    <!-- AVAILABLE COLUMNS -->
    <div class="columns-picker-available-parent z-0 m-0 w-full overflow-auto pb-3">
      <h3 class="sticky top-0 m-0 bg-white p-4 pr-6 text-center">
        Available columns
      </h3>

      <ul ref="availableParentRef" class="m-0 size-full p-4 pr-6 pt-0">
        <li
          v-for="item in availableColumns"
          :key="item.id"
          class="columns-picker-list-item"
          :class="columnsPickerListItemClass"
          :data-id="item.id"
        >
          <span>{{ item.label }}</span>
          <i class="fa-light fa-grip-dots-vertical" />
        </li>
      </ul>
    </div>

    <!-- BUTTONS -->
    <div class="pointer-events-none absolute z-10 flex size-full flex-col items-center justify-center gap-2">
      <VdButton
        class="columns-picker-button"
        :class="columnsPickerButtonClass"
        icon="fa-light fa-chevron-right"
        outlined
        size="small"
        @click="onMoveToSelected"
      />
      <VdButton
        class="columns-picker-button"
        :class="columnsPickerButtonClass"
        icon="fa-light fa-chevrons-right"
        outlined
        size="small"
        @click="onMoveAllToSelected"
      />
      <VdButton
        class="columns-picker-button"
        :class="columnsPickerButtonClass"
        icon="fa-light fa-chevron-left"
        outlined
        size="small"
        @click="onMoveToAvailable"
      />
      <VdButton
        class="columns-picker-button"
        :class="columnsPickerButtonClass"
        icon="fa-light fa-chevrons-left"
        outlined
        size="small"
        @click="onMoveAllToAvailable"
      />
    </div>

    <!-- SELECTED COLUMNS -->
    <div class="columns-picker-selected-parent m-0 w-full overflow-auto pb-3">
      <h3 class="sticky top-0 m-0 bg-white p-4 pl-6 text-center">
        Selected columns
      </h3>

      <ul ref="selectedParentRef" class="m-0 size-full p-4 pl-6 pt-0">
        <li
          v-for="item in selectedColumns"
          :key="item.label"
          class="columns-picker-list-item"
          :class="columnsPickerListItemClass"
          :data-id="item.id"
        >
          <span>{{ item.label }}</span>
          <i class="fa-light fa-grip-dots-vertical" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.columns-picker-component {
  border: 1px solid var(--border-color);

  .columns-picker-available-parent {
    border-right: 1px solid var(--border-color);
  }
}

.columns-picker-list-item {
  &:nth-child(odd) {
    background-color: rgba(var(--primary-color-rgb), 0.05);
  }
}

.columns-picker-button {
  border: 1px solid var(--border-color);
}
</style>
