import { AdFormat } from "@/graphql";

export function prettifyAdFormat(adFormat: AdFormat): string {
  const mapping: Record<AdFormat, string> = {
    [AdFormat.Video]: "Video",
    [AdFormat.Playable]: "Playable",
    [AdFormat.VideoPlayable]: "Video + Playable",
    [AdFormat.VideoIec]: "Video + IEC",
    [AdFormat.BeRealFeed]: "BeReal Feed",
  };
  return mapping[adFormat];
}
