<script lang="ts" setup>
import Tag from "primevue/tag";
import { computed } from "vue";

import { CAMPAIGN_OBJECTIVE_OPTIONS, type CampaignObjective } from "@/constants";

const props = defineProps<{
  campaignOjective: CampaignObjective | null;
}>();

const campaignObjectiveInfo = computed(() => CAMPAIGN_OBJECTIVE_OPTIONS.find(obj => obj.value === props.campaignOjective));
</script>

<template>
  <div class="text-center">
    <Tag
      v-if="campaignObjectiveInfo"
      :value="campaignObjectiveInfo.label"
      :icon="`fa-light font-normal ${campaignObjectiveInfo.icon}`"
      class="campaign-type-tag-component rounded-xl bg-indigo-100 px-2 font-semibold text-indigo-600"
    />
  </div>
</template>
