import type { SortingFn } from "@tanstack/vue-table";

import type { AppFragment } from "@/graphql";

export const sortCampaignApp: SortingFn<{ app?: AppFragment }> = (rowA, rowB) => {
  const appA = rowA.original.app;
  const appB = rowB.original.app;

  return appA?.name.localeCompare((appB?.name ?? "")) ?? 0;
};
