import { type MaybeRefOrGetter, toValue } from "vue";

import { buildQuery, buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

export const query = graphql(/* GraphQL */ `
  query AdTestAppEndpoints($campaignId: ID!, $adIds: [ID!]!) {
    adTestAppEndpoints(campaignId: $campaignId, adIds: $adIds) {
      id
      url
    }
  }
`);

export function getQueryOptions(campaignId: MaybeRefOrGetter<string>, adIds: MaybeRefOrGetter<string[]>, enabled = false) {
  return {
    queryKey: buildQueryKey(query, { campaignId, adIds }),
    async queryFn() {
      return runGraphqlQuery(query, { campaignId: toValue(campaignId), adIds: toValue(adIds) });
    },
    enabled,
  };
}

export function useAdTestAppEndpointsQuery({
  campaignId,
  adIds,
  enabled,
}: {
  campaignId: MaybeRefOrGetter<string>;
  adIds: MaybeRefOrGetter<string[]>;
  enabled?: boolean;
}) {
  return buildQuery(getQueryOptions(campaignId, adIds, enabled));
}
