<script setup lang="ts">
import { tv } from "tailwind-variants";

import AppIcon from "@/components/business/AppIcon/AppIcon.vue";
import StoreIcon from "@/components/business/StoreIcon/StoreIcon.vue";
import type { Store } from "@/graphql";
import type { Size } from "@/types/common";

withDefaults(defineProps<{
  name?: string | null;
  author?: string | null;
  iconUrl?: string | null;
  store?: Store | null;
  size?: Size;
  wrap?: boolean;
  withTooltip?: boolean;
}>(), {
  name: null,
  author: null,
  size: "normal",
  iconUrl: null,
  store: null,
  wrap: false,
  withTooltip: false,
});

const appChipNameClass = tv({
  base: "app-chip-component__name flex overflow-hidden leading-5",
  variants: {
    wrap: { true: "flex-col items-start gap-0", false: "items-center gap-1" },
  },
});
</script>

<template>
  <div class="app-chip-component flex max-w-[25rem] items-center gap-2 overflow-hidden">
    <AppIcon v-if="iconUrl" :url="iconUrl" :store="store" :size="size" />
    <div
      v-if="name"
      v-tooltip.top="withTooltip ? { value: name } : undefined"
      :class="appChipNameClass({ wrap })"
    >
      <span
        data-test="app-name"
        class="app-chip-component__app-name text-color max-w-full truncate font-semibold"
      >{{ name }}</span>
      <StoreIcon v-if="store && size === 'small'" :store="store" />
      <span v-if="author" class="app-chip-component__author text-color-secondary w-full truncate text-sm italic leading-4">{{ author }}</span>
    </div>
  </div>
</template>
