import type { ColumnDef } from "@tanstack/vue-table";
import { createColumnHelper } from "@tanstack/vue-table";
import type { Component, ComputedRef } from "vue";
import { computed, h, unref } from "vue";

import TableHeader from "./components/TableHeader/TableHeader.vue";
import type { TableColumn } from "./types";

type GetColumnComponentFnArgs<T> = {
  tableColumn: TableColumn<T, Component | null>;
  row: T;
};

function getColumnComponent<T>({ tableColumn, row }: GetColumnComponentFnArgs<T>) {
  const componentProps = tableColumn.componentProps ? tableColumn.componentProps({ row }) as object : {};

  if (tableColumn.component) {
    return h(tableColumn.component, { ...componentProps });
  }

  return h("div", row[tableColumn.id] as string);
};

type UseVirtualTableType<T extends { [key: string]: unknown }> = {
  columns: ComputedRef<TableColumn<T, Component | null>[]>;
  getColumnComponentFn?: (args: GetColumnComponentFnArgs<T>) => ReturnType<typeof h>;
};

export function useVirtualTable<T extends { [key: string]: unknown }>({
  columns,
  getColumnComponentFn = getColumnComponent,
}: UseVirtualTableType<T>,
) {
  const columnHelper = createColumnHelper<T>();

  const columnDefs = computed<ColumnDef<T, unknown>[]>(() => unref(columns).map((tableColumn) => {
    // eslint-disable-next-line ts/no-unsafe-return, ts/no-unsafe-member-access, ts/no-explicit-any
    return columnHelper.accessor((row: any) => row[tableColumn.id], {
      id: tableColumn.id as string,

      // eslint-disable-next-line ts/no-explicit-any, ts/no-unsafe-assignment
      header: ({ header }) => h(TableHeader, { label: tableColumn.label, header: header as unknown as any }), // TODO: remove any
      cell: ({ row }) => getColumnComponentFn({ tableColumn, row: row.original }),
      minSize: tableColumn.minWidth as number, // TODO: remove as number
      maxSize: tableColumn.maxWidth,
      enableSorting: tableColumn.isSortable,
      sortingFn: tableColumn.sortingFn ?? "alphanumeric",
      sortDescFirst: false,
      meta: {
        isPinnedLeft: tableColumn.isPinnedLeft,
        isPinnedRight: tableColumn.isPinnedRight,
        isCentered: tableColumn.isCentered,
      },
    });
  }));

  return { columnDefs };
}
