import { FilterMatchMode } from "primevue/api";
import { z } from "zod";

import type { FilterConfig } from "@/components/business/ListFilters/types";
import usePermissions from "@/composables/use-permissions";
import { DEFAULT_REPORTING_FILTERS_CONFIG, useStateFilters } from "@/composables/use-state-filters";
import { FILTERS_MODES_NUMERICAL_FLOAT } from "@/constants";
import { AdPlacement, CampaignStatus, DeviceType, PerformanceCampaignRoasType, PerformanceCampaignType, Store } from "@/graphql";
import { booleanField } from "@/utils/filters/boolean-filters";
import { CONTAINS_ALL, filterArray } from "@/utils/filters/common";
import { numericalFieldFloat, numericFloatDefaultValue } from "@/utils/filters/numerical-filters";

import type { CampaignsListPageAwarenessCampaignRow, CampaignsListPageCommonCampaignRow, CampaignsListPageConversionCampaignRow } from "./types";

const globalFilter: FilterConfig = {
  id: "global",
  title: "Search",
  initialValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
  schema: {
    field: z.object({ value: z.string().nullable(), matchMode: z.literal(FilterMatchMode.CONTAINS) }),
    multi: false,
    encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["CONTAINS"] }) => obj.value,
    decode: value => ({ value, matchMode: FilterMatchMode.CONTAINS }),
  },
  inputComponent: "SearchFilterInput",
  tagValueComponent: null,
  isAlwaysDisplayed: true,
};

const statusFilter: FilterConfig = {
  id: "status",
  title: "Status",
  initialValue: { value: [], matchMode: FilterMatchMode.IN },
  schema: {
    field: z.object({ value: z.array(z.nativeEnum(CampaignStatus)), matchMode: z.literal(FilterMatchMode.IN) }),
    multi: true,
    encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
    decode: value => ({ value, matchMode: FilterMatchMode.IN }),
  },
  inputComponent: "StatusFilterInput",
  tagValueComponent: "StatusFilterTag",
  isAlwaysDisplayed: false,
};

const countriesFilter: FilterConfig = {
  id: "countries",
  title: "Countries",
  initialValue: { value: [], matchMode: CONTAINS_ALL },
  schema: {
    field: z.object({ value: z.array(z.string()), matchMode: z.literal(CONTAINS_ALL) }),
    multi: true,
    encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
    decode: value => ({ value, matchMode: CONTAINS_ALL }),
  },
  inputComponent: "CountriesFilterInput",
  tagValueComponent: "CountryFilterTag",
  isAlwaysDisplayed: false,
};

const storeFilter: FilterConfig = {
  id: "store",
  title: "Store",
  initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
  schema: {
    field: z.object({ value: z.nativeEnum(Store).nullable(), matchMode: z.literal(FilterMatchMode.EQUALS) }),
    multi: false,
    encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["EQUALS"] }) => obj.value,
    decode: value => ({ value, matchMode: FilterMatchMode.EQUALS }),
  },
  inputComponent: "StoreFilterInput",
  tagValueComponent: "StoreFilterTag",
  isAlwaysDisplayed: false,
};

const appIdFilter: FilterConfig = {
  id: "app.id",
  title: "Apps",
  initialValue: { value: [], matchMode: FilterMatchMode.IN },
  schema: {
    field: z.object({
      value: z.string().or(z.array(z.string())).nullable(),
      matchMode: z.literal(FilterMatchMode.IN),
    }),
    multi: true,
    encode: (obj: { value: string[] | null; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
    decode: value => ({ value, matchMode: FilterMatchMode.IN }),
  },
  inputComponent: "AppFilterInput",
  tagValueComponent: "AppFilterTag",
  isAlwaysDisplayed: false,
};

const deviceTypeFilter: FilterConfig = {
  id: "deviceType",
  title: "Device type",
  initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
  schema: {
    field: z.object({ value: z.nativeEnum(DeviceType).nullable(), matchMode: z.literal(FilterMatchMode.EQUALS) }),
    multi: false,
    encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["EQUALS"] }) => obj.value,
    decode: value => ({ value, matchMode: FilterMatchMode.EQUALS }),
  },
  inputComponent: "DeviceTypeFilterInput",
  tagValueComponent: "DeviceTypeFilterTag",
  isAlwaysDisplayed: false,
};

const campaignTypeFilter: FilterConfig = {
  id: "campaignType",
  title: "Type",
  initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
  schema: {
    field: z.object({
      value: z.nativeEnum(PerformanceCampaignType).or(z.nativeEnum(PerformanceCampaignRoasType)).nullable(),
      matchMode: z.literal(FilterMatchMode.EQUALS),
    }),
    multi: false,
    encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["EQUALS"] }) => obj.value,
    decode: value => ({ value, matchMode: FilterMatchMode.EQUALS }),
  },
  inputComponent: "CampaignTypeFilterInput",
  tagValueComponent: "CampaignTypeTag",
  isAlwaysDisplayed: false,
};

const isTestModeFilter: FilterConfig = {
  id: "isTestMode",
  title: "Test mode",
  initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
  schema: booleanField,
  inputComponent: "IsTestModeFilterInput",
  tagValueComponent: "IsTestModeFilterTag",
  isAlwaysDisplayed: false,
  visibility: () => {
    const { hasCampaignTestModeWritePermission } = usePermissions();
    return hasCampaignTestModeWritePermission.value;
  },
};

const todaySpendFilter: FilterConfig = {
  id: "todaySpend",
  title: "Today's spend",
  initialValue: numericFloatDefaultValue,
  schema: numericalFieldFloat,
  inputComponent: "NumericalFilterInput",
  tagValueComponent: "NumericalFilterTag",
  format: { style: "currency", currency: "USD" },
  matchModes: FILTERS_MODES_NUMERICAL_FLOAT,
};

const dailyBudgetFilter: FilterConfig = {
  id: "dailyBudget",
  title: "Daily budget",
  initialValue: numericFloatDefaultValue,
  schema: numericalFieldFloat,
  inputComponent: "NumericalFilterInput",
  tagValueComponent: "NumericalFilterTag",
  format: { style: "currency", currency: "USD" },
  matchModes: FILTERS_MODES_NUMERICAL_FLOAT,
};

const adPlacementFilter: FilterConfig = {
  id: "adPlacement",
  title: "Placement",
  initialValue: { value: [], matchMode: FilterMatchMode.IN },
  schema: {
    field: z.object({ value: z.array(z.nativeEnum(AdPlacement)), matchMode: z.literal(FilterMatchMode.IN) }),
    multi: true,
    encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
    decode: value => ({ value, matchMode: FilterMatchMode.IN }),
  },
  inputComponent: "AdPlacementFilterInput",
  tagValueComponent: "AdPlacementFilterTag",
  isAlwaysDisplayed: false,
};

const filtersConfig = {
  "global": globalFilter,
  "status": statusFilter,
  "countries": countriesFilter,
  "store": storeFilter,
  "app.id": appIdFilter,
  "deviceType": deviceTypeFilter,
  "campaignType": campaignTypeFilter,
  "isTestMode": isTestModeFilter,
  "todaySpend": todaySpendFilter,
  "dailyBudget": dailyBudgetFilter,
  "adPlacement": adPlacementFilter,
  ...DEFAULT_REPORTING_FILTERS_CONFIG,
} as const satisfies Partial<Record<
  | keyof CampaignsListPageConversionCampaignRow
  | keyof CampaignsListPageAwarenessCampaignRow
  | "app.id"
  | "global",
  FilterConfig
>>;

const campaignsListGlobalFiltersKeys = ["name", "appId", "app.name"];

/**
 * Composable to manage the filters of the campaigns list.
 */
export function useFilters() {
  const { filters, clear, initialValues } = useStateFilters(filtersConfig);

  function filterRows(rows: CampaignsListPageCommonCampaignRow[]) {
    return filterArray(filters.value, rows, campaignsListGlobalFiltersKeys);
  }

  return {
    filters,
    initialValues,
    config: filtersConfig,
    clear,
    filterRows,
  };
}
