<script setup lang="ts" generic="T">
import Checkbox from "primevue/checkbox";
import { computed } from "vue";

const props = defineProps<{
  value: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  tooltipText?: string;
}>();

const emit = defineEmits<{
  change: [event: unknown];
}>();

function handleChange(event: unknown) {
  emit("change", event);
}

const tooltipValue = computed(() => {
  return { value: props.tooltipText, disabled: !props.disabled };
});
</script>

<template>
  <span v-tooltip="tooltipValue">
    <Checkbox
      :model-value="value"
      :disabled="disabled"
      :indeterminate="indeterminate"
      binary
      @change="handleChange"
    />
  </span>
</template>

<style>
    [data-p-indeterminate="true"] .p-checkbox-box {
        background: #EB585C !important;
    }
    [data-p-indeterminate="true"] .p-checkbox-box .p-checkbox-icon {
        color: white !important;
    }
</style>
