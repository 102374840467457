<script lang="ts" setup generic="T extends { [key: string]: unknown }">
import type { Header } from "@tanstack/vue-table";
import { computed } from "vue";

const props = defineProps<{
  label: string;
  header: Header<T, T[keyof T]>;
}>();

const isSortingEnabled = computed(() => props.header.column.columnDef.enableSorting === true);

const sortDirection = computed(() =>
  isSortingEnabled.value === true && props.header.column.getIsSorted(),
);

const sortIcon = computed(() => {
  if (sortDirection.value) {
    return sortDirection.value === "asc" ? "fa-arrow-up-short-wide" : "fa-arrow-down-wide-short";
  }

  return "fa-arrow-down-arrow-up";
});

function onSort() {
  if (isSortingEnabled.value) {
    props.header.column.toggleSorting();
  }
}
</script>

<template>
  <div
    class="flex items-center gap-3"
    :class="{
      'text-primary': sortDirection,
      'cursor-pointer': isSortingEnabled,
    }"
    @click="onSort"
  >
    <span>{{ label }}</span>

    <i v-if="header.column.columnDef.enableSorting === true" :class="`fa fa-light font-light ${sortIcon}`" />
  </div>
</template>
