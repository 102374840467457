import qrCode from "qrcode";

export async function generateQRCode(text: string): Promise<string | null> {
  try {
    return await qrCode.toDataURL(text, { width: 400, margin: 0 });
  }
  catch (err) {
    console.error(err);
    return null;
  }
}
