<script setup lang="ts" generic="T extends { [key: string]: unknown  }, U extends Partial<T>">
import type { Column, ColumnDef, Row, RowSelectionState, Table } from "@tanstack/vue-table";
import { FlexRender, getCoreRowModel, getSortedRowModel, useVueTable } from "@tanstack/vue-table";
import { useVirtualizer } from "@tanstack/vue-virtual";
import { useElementSize, useWindowSize } from "@vueuse/core";
import ProgressSpinner from "primevue/progressspinner";
import { computed, type CSSProperties, h, ref, watch } from "vue";

import SelectionCell from "@/components/business/TableCells/common/SelectionCell/SelectionCell.vue";
import { useHorizontalScrollBoundaries } from "@/composables/use-horizontal-scroll-boundaries";

const props = withDefaults(defineProps<{
  data: T[];
  columns: ColumnDef<U, unknown>[];
  height?: number;
  rowHeight?: number;
  isSelectable?: boolean;
  selection?: RowSelectionState;
  isInfiniteLoader?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  isSelectAllEnabled?: boolean;
  isSelectAllTooltipText?: string;
  rowClass?: string;
}>(), {
  rowClass: "px-3 py-2",
  rowHeight: 50,
  isInfiniteLoader: false,
  hasNextPage: false,
  totalRowsCount: 0,
  isFetchingNextPage: false,
  isSelectAllEnabled: true,
});

const emit = defineEmits<{
  "row-selection-change": [value: RowSelectionState];
  "load-more": [];
}>();

const MIN_LIST_HEIGHT = 500;
const ACTIONS_COLUMN_WIDTH = 42;
const MIN_LIST_WIDTH = 400;
const MIN_COLUMN_WIDTH = 150;

const rowSelection = ref<RowSelectionState>({});

watch(() => props.selection, (value) => {
  if (value) {
    rowSelection.value = value;
  }
}, { immediate: true, deep: true });

const columnsWithSelection = computed<ColumnDef<T, unknown>[]>(() => {
  if (!props.isSelectable) {
    return props.columns as ColumnDef<T, unknown>[];
  }

  return [
    {
      id: "select",
      header: ({ table }: { table: Table<T> }) => {
        return h(
          SelectionCell,
          {
            value: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
            disabled: !props.isSelectAllEnabled,
            tooltipText: props.isSelectAllTooltipText,
          },
        );
      },
      cell: ({ row }: { row: Row<T> }) => {
        return h(
          SelectionCell,
          {
            value: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler(),
          },
        );
      },
      meta: {
        isPinnedLeft: true,
      },
      minSize: 40,
      maxSize: 40,
    },

    ...(props.columns as ColumnDef<T, unknown>[]),
  ];
});

type MetaType = {
  isPinnedLeft?: boolean;
  isPinnedRight?: boolean;
  isCentered?: boolean;
};

const tableContainerRef = ref();
const tableRef = ref();

const { height: tableRefHeight } = useElementSize(tableRef);
const { width: containerWidth } = useElementSize(tableContainerRef);
const { height: windowInnerHeight } = useWindowSize();

const pinnedLeftColumnsIds = computed(() => {
  let pinnedColumnsTotalWidth = 0;
  /** Handles pinned columns order */
  let hasReachedMax = false;

  return columnsWithSelection.value.reduce((acc, column) => {
    if (column.id && (column.meta as MetaType)?.isPinnedLeft) {
      const minSize = column.minSize ?? MIN_COLUMN_WIDTH;

      if ((pinnedColumnsTotalWidth + minSize + MIN_LIST_WIDTH > containerWidth.value) || hasReachedMax) {
        hasReachedMax = true;
        return acc;
      }

      acc.push(column.id);
      pinnedColumnsTotalWidth += minSize;
    }

    return acc;
  }, [] as string[]);
});

function getCommonPinningStyles(column: Column<T>): CSSProperties {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : 0,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 10 : 0,
  };
}

function onRowSelectionChange(updateOrValue: RowSelectionState | ((prev: RowSelectionState) => RowSelectionState)) {
  rowSelection.value = typeof updateOrValue === "function"
    ? updateOrValue(rowSelection.value)
    : updateOrValue;

  emit("row-selection-change", rowSelection.value);
}

const options = computed(() => ({
  get data() {
    return props.data;
  },
  get columns() {
    return columnsWithSelection.value;
  },
  state: {
    get rowSelection() {
      return rowSelection.value;
    },
    get columnPinning() {
      return {
        left: pinnedLeftColumnsIds.value,
        right: [],
      };
    },
  },
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getRowId: (row: T) => row.id as string,
  enableRowSelection: true,
  onRowSelectionChange,
}));

const table = useVueTable(options.value);

const rows = computed(() => {
  return table.getRowModel().rows;
});

const rowVirtualizerOptions = computed(() => {
  return {
    count: rows.value.length,
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => props.rowHeight,
    overscan: 20,
  };
});

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());

const { hasScrollReachedRight, hasScrollReachedLeft } = useHorizontalScrollBoundaries(tableContainerRef);

function calculateColumnWidth(column: Column<T, unknown>) {
  const allColumns = table.getAllFlatColumns();

  /** We need to calculate the width of all columns + actions column */
  const columnsWidth = allColumns.reduce((acc, column) => {
    /** If the column has a max size, we need to respect it */
    if (column.columnDef.maxSize) {
      return acc + column.columnDef.maxSize;
    }

    return acc + (column.columnDef.minSize ?? column.getSize());
  }, 0) + ACTIONS_COLUMN_WIDTH;

  const tableWidth = tableContainerRef.value?.offsetWidth;

  /** Calculate the number of columns that have a max size */
  const maxSizeColumnsLength = allColumns.filter(column => column.columnDef.maxSize).length;

  /** Calculate the number of columns that don't have a max size, as they shouldn't be considered in the calculation */
  const columnsLength = allColumns.length - maxSizeColumnsLength;

  /** Calculate the remaining width */
  const remainingWidth = tableWidth - columnsWidth;

  /** If there is no remaining width, we can just return the column size */
  if (remainingWidth <= 0) {
    return column.getSize();
  }

  const delta = remainingWidth / columnsLength;

  /** If the column has a max size, we need to respect it */
  if (column.columnDef.maxSize) {
    return column.columnDef.maxSize;
  }

  /** If the column has a minSize, we need to respect it */
  if (column.columnDef.minSize) {
    return column.columnDef.minSize + delta;
  }

  /** Otherwise, we can just add the delta to the column size */
  return column.getSize() + delta;
}

function fetchMoreOnBottomReached() {
  if (!props.isInfiniteLoader) {
    return;
  }

  const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.value;

  if (scrollHeight - scrollTop - clientHeight < 800) {
    emit("load-more");
  }
}

const tableHeight = computed<string>(() => {
  if (props.height) {
    return `${props.height}px`;
  }

  const listEstimatedHeight = windowInnerHeight.value - (tableContainerRef.value?.getBoundingClientRect().top ?? 0);

  if (tableRefHeight.value < listEstimatedHeight) {
    /* 2px handles the border */
    return `${tableRefHeight.value + 2}px`;
  }

  if (listEstimatedHeight < MIN_LIST_HEIGHT) {
    const totalHeight = tableRefHeight.value > MIN_LIST_HEIGHT ? MIN_LIST_HEIGHT : (tableRefHeight.value + 2);
    return `${totalHeight}px`;
  }

  const listHeight = listEstimatedHeight > tableRefHeight.value ? tableRefHeight.value : listEstimatedHeight - 80;

  return `${listHeight}px`;
});
</script>

<template>
  <div class="flex w-full flex-col gap-3">
    <slot name="header" />

    <div
      ref="tableContainerRef"
      class="relative w-full overflow-auto"
      :style="{ height: tableHeight }"
      @scroll="fetchMoreOnBottomReached"
    >
      <table ref="tableRef" class="w-full" cellpadding="0" cellspacing="0">
        <thead class="sticky top-0 z-20 bg-white">
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
            class="flex w-full"
          >
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              :colSpan="header.colSpan"
              class="flex items-center border-0 border-y border-solid border-primary-100 bg-white p-3 text-left"
              :style="{ ...getCommonPinningStyles(header.column), width: `${calculateColumnWidth(header.column)}px` }"
              :class="{
                'justify-center': (header.column.columnDef.meta as MetaType)?.isCentered,
                'column-left-raised': header.column.getIsPinned() === 'left' && !hasScrollReachedLeft,
              }"
            >
              <div class="whitespace-nowrap text-left">
                <FlexRender
                  v-if="!header.isPlaceholder"
                  :render="header.column.columnDef.header"
                  :props="header.getContext()"
                />
              </div>
            </th>

            <th
              key="actions-button"
              class="sticky right-0 z-10 border-0 border-y border-solid border-primary-100 bg-white"
              :class="{
                'column-right-raised': !hasScrollReachedRight,
              }"
              :style="{ width: `${ACTIONS_COLUMN_WIDTH}px` }"
            />
          </tr>
        </thead>

        <tbody class="relative grid" :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
          <tr
            v-for="virtualRow in virtualRows"
            :key="(virtualRow.key as string)"
            class="vd-virtual-table-row absolute flex w-full"
            :style="{ transform: `translateY(${virtualRow.start}px)` }"
          >
            <td
              v-for="cell in rows[virtualRow.index]?.getVisibleCells()"
              :key="cell.id"
              :style="{ ...getCommonPinningStyles(cell.column), width: `${calculateColumnWidth(cell.column)}px` }"
              class="vd-virtual-table-cell flex items-center border-0 border-b border-solid border-primary-100"
              :class="[
                rowClass,
                {
                  'justify-center': (cell.column.columnDef.meta as MetaType)?.isCentered,
                  'bg-primary-50': virtualRow.index % 2,
                  'bg-white': !(virtualRow.index % 2),
                  'column-left-raised': cell.column.getIsPinned() === 'left' && !hasScrollReachedLeft,
                }]"
            >
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"
              />
            </td>

            <td
              class="vd-virtual-table-cell sticky right-0 z-10 flex items-center border-0 border-b border-solid border-primary-100"
              :class="{
                'bg-primary-50': virtualRow.index % 2,
                'bg-white': !(virtualRow.index % 2),
                'column-right-raised': !hasScrollReachedRight,
              }"
              :style="{ width: `42px` }"
            >
              <slot name="actions" :row="rows[virtualRow.index]?.original" />
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="isInfiniteLoader && hasNextPage"
        class="sticky bottom-2 z-10 flex w-full justify-center transition-opacity"
        :class="{
          'opacity-0': !isFetchingNextPage,
          'opacity-1': isFetchingNextPage,
        }"
      >
        <div class="white-box flex items-center gap-2 rounded-full px-4 py-3">
          <ProgressSpinner
            stroke-width="4"
            :style="{ width: '12px', height: '12px' }"
          />

          <span class="text-gray-800">Loading data</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Handle frozen columns */
.column-left-raised:after {
  right: -11px;
  box-shadow: inset 10px 0 10px -10px rgba(var(--primary-color-rgb), 0.3);
}

.column-right-raised:after {
  left: -11px;
  box-shadow: inset -10px 0 10px -10px rgba(var(--primary-color-rgb), 0.3);
}

.column-left-raised:after,
.column-right-raised:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 10px;
}
</style>
