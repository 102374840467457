import type { SortingFn } from "@tanstack/vue-table";

import { CAMPAIGN_STATUS_ORDER } from "@/constants";
import type { CampaignStatus } from "@/graphql";

export const sortCampaignStatus: SortingFn<{ status: CampaignStatus }> = (rowA, rowB) => {
  const statusA = rowA.original.status;
  const statusB = rowB.original.status;

  return CAMPAIGN_STATUS_ORDER.indexOf(statusA) - CAMPAIGN_STATUS_ORDER.indexOf(statusB);
};
