import type { MaybeRef } from "@vueuse/core";
import { type ComputedRef, unref } from "vue";

import { buildQuery, buildQueryKey, type CampaignsListPageQueryVariables, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query CampaignsListPage($status: [CampaignStatus!]!, $showTestMode: Boolean, $sorts: String) {
    campaigns(first: 10000, status: $status, showTestMode: $showTestMode, sorts: $sorts) {
      edges {
        node {
          campaign {
            __typename
            ...on PerformanceCampaign {
              ...campaignsListPageConversionCampaign
            }
            ...on BrandingCampaign {
              ...campaignsListPageAwarenessCampaign
            }
          }
        }
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment campaignsListPageConversionCampaign on PerformanceCampaign {
    __typename
    id
    name
    deviceType
    status
    countries
    isTestMode
    totalDailyBudget

    store
    type
    roasType
    appId
    app {
      ...app
    }
    adsManagement {
      adsPlacement
    }
  }

  fragment campaignsListPageAwarenessCampaign on BrandingCampaign {
    __typename
    id
    name
    deviceType
    status
    countries
    isTestMode
    dailyBudget

    platforms
    startDate
    endDate
    cpm
    nbMaxAdsFrequency
    nbDaysAdsFrequency
    creationDate
    updateDate
  }
`);

export function getQueryOptions(variables: MaybeRef<CampaignsListPageQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, unref(variables));
    },
  };
}

export function useCampaignsListPageQuery(variables: ComputedRef<CampaignsListPageQueryVariables>) {
  return buildQuery(getQueryOptions(variables));
}
