import type { Ref } from "vue";

import {
  buildQuery,
  buildQueryKey,
  type CampaignsListPageReportsQueryVariables,
  graphql,
  runGraphqlQuery,
} from "@/graphql";

const query = graphql(/* GraphQL */ `
  query CampaignsListPageReports($startDate: String!, $endDate: String!, $today: String!) {
    campaigns(first: 1000, reportStartDate: $startDate, reportEndDate: $endDate, reportType: PROBABILISTIC) {
      edges {
        node {
          campaign {
            __typename
            ...on PerformanceCampaign {
              id
            }
            ...on BrandingCampaign {
              id
            }
          }
          report {
            ...report
          }
        }
      }
    }
    todaySpentBudgetByCampaignIds: campaigns(first: 1000, reportStartDate: $today, reportEndDate: $today, reportType: PROBABILISTIC) {
      edges {
        node {
          campaign {
            __typename
            ...on PerformanceCampaign {
              id
            }
            ...on BrandingCampaign {
              id
            }
          }
          report {
            billableSpend
          }
        }
      }
    }
  }
`);

function getQueryOptions(variables: Ref<CampaignsListPageReportsQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, variables.value);
    },
  };
}

export function useCampaignsListPageReportsQuery(
  variables: Ref<CampaignsListPageReportsQueryVariables>,
  { enabled }: { enabled: Ref<boolean> },
) {
  return buildQuery({
    ...getQueryOptions(variables),
    enabled,
  });
}
