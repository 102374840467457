import { prettifyNumericalValue } from "./prettify-numerical-value";

export function prettifyPercent(
  value: number | null | undefined,
  options?: {
    format?: Intl.NumberFormatOptions;
    displaySign: boolean;
  },
): string {
  if (value == null) {
    return "";
  }

  const displaySign = options?.displaySign ?? false;

  return prettifyNumericalValue(value, {
    format: {
      style: "percent",
      maximumFractionDigits: options?.format?.maximumFractionDigits ?? 2,
      signDisplay: displaySign ? "exceptZero" : "auto",
    },
    percentMode: options?.format?.maximumFractionDigits == null ? "absolute" : "decimal",
  });
}
