import vueAuth0, { type FetchUser } from "@voodoo.io/vue-auth0";
import * as jose from "jose";

import { config } from "@/config";

import { useLogRocket } from "./use-log-rocket";
import { useSentry } from "./use-sentry";

const { setUser: setSentryUser } = useSentry();
const { setUser: setLogRocketUser } = useLogRocket();

/**
 * Return the info inside the accessToken.
 */
async function decodeAccessToken(
  accessToken: string,
): Promise<jose.JWTPayload> {
  const jwks = jose.createRemoteJWKSet(new URL(config.auth0.jwksUrl));
  const { payload } = await jose.jwtVerify(accessToken, jwks, {
    issuer: config.auth0.issuer,
    audience: config.auth0.audience,
  });
  return payload;
}

export type User = {
  id: string;
  email: string;
  organizationId: string;
};

const fetchUser: FetchUser<User> | never = async (accessToken: string) => {
  if (!accessToken) {
    throw new Error("AUTHENTICATION_ERROR");
  }

  let result;
  try {
    result = await decodeAccessToken(accessToken);
  }
  catch (error) {
    console.error("Error decoding access token", error);
    throw new Error("AUTHENTICATION_ERROR");
  }

  const payload = result as {
    "sub": string;
    "https://voodoo.io/email": string;
    "org_id": string;
    "permissions"?: string[];
  };

  const userId = payload.sub;
  const email = payload["https://voodoo.io/email"];
  const organizationId = payload.org_id;
  if (!userId || !email || !organizationId) {
    console.error("Missing user info in the access token", payload);
    throw new Error("AUTHENTICATION_ERROR");
  }

  void setSentryUser(email);
  void setLogRocketUser({ userId, email, organizationId });

  return {
    user: { id: userId, email, organizationId },
    permissions: payload.permissions ?? [],
  };
};

const accessTokenStorageKey = `${config.localStoragePrefix ?? ""}-${config.environment}-voodoo-app-access-token`;

export default vueAuth0<User>({
  loginUrl: "/login",
  auth0LogoutRedirectUrl: config.auth0.logoutRedirectUrl,
  auth0Domain: config.auth0.domain,
  auth0ClientId: config.auth0.clientId,
  auth0CallbackRedirectUrl: config.auth0.callbackRedirectUrl,
  auth0Audience: config.auth0.audience,
  accessTokenStorageKey,
  fetchUser,
});
