<script setup lang="ts">
import Button from "primevue/button";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useAdTestAppEndpointsQuery } from "@/composables/ad-test-app/use-ad-test-app-enpoints";
import links from "@/router/links";
import { generateQRCode } from "@/utils/generate-qrcode";

const props = defineProps<{
  adId: string;
  campaignId: string;
}>();

const router = useRouter();

const adIds = computed(() => [props.adId]);
const campaignId = computed(() => props.campaignId);

const { data } = useAdTestAppEndpointsQuery({
  adIds,
  campaignId,
  enabled: true,
});

const qrCode = ref();

const endPointUrl = computed(() => data.value?.adTestAppEndpoints[0]?.url);

function goToAdsPage() {
  router.push({ name: links.campaigns.details.ads, params: { campaignId: props.campaignId } });
}

watch(endPointUrl, async (url) => {
  if (url) {
    qrCode.value = await generateQRCode(url);
  }
});
</script>

<template>
  <div class="white-box text-secondary flex flex-col items-center">
    <div class="py-6 text-center">
      Your ad contains an HTML asset, maybe you want to check it on your test app before publishing it ?
    </div>
    <div class="h-full max-h-[70vh] max-w-full">
      <img v-if="qrCode" :src="qrCode" alt="QR code" class="max-h-full max-w-full object-contain">
    </div>

    <Button class="mt-4" @click="goToAdsPage">
      Skip this step
    </Button>
  </div>
</template>
