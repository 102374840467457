import type LogRocket from "logrocket";

import { config } from "@/config";

type LogRocketInstance = typeof LogRocket;

export function useLogRocket() {
  const logRocketAppId = config.loggers.logRocketAppId;

  async function getInstance(): Promise<LogRocketInstance | null> {
    if (logRocketAppId != null && logRocketAppId !== "") {
      const instance = await import("logrocket");
      return instance.default;
    }
    return null;
  }

  async function initLogRocket(): Promise<boolean> {
    const logRocketAppId = config.loggers.logRocketAppId;

    if (logRocketAppId == null || logRocketAppId === "") {
      return false;
    }

    const logRocket = await getInstance();

    logRocket?.init(logRocketAppId, {
      shouldParseXHRBlob: true,
      network: {
        requestSanitizer: (request) => {
          if ((request.body as unknown) instanceof File) {
            delete request.body;
          }
          else if (request.body?.includes("password") === true) {
            request.body = undefined;
          }
          return request;
        },
      },
    });
    return true;
  }

  async function setUser(params: { userId: string; email: string; organizationId: string }) {
    const logRocket = await getInstance();
    logRocket?.identify(params.userId, {
      email: params.email,
      organizationId: params.organizationId,
    });
  }

  return {
    init: initLogRocket,
    getInstance,
    setUser,
  };
}
