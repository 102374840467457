<script setup lang="ts">
import { computed } from "vue";

import VdEmpty from "@/components/structures/VdEmpty/VdEmpty.vue";
import VdTableSkeleton from "@/components/structures/VdTableSkeleton/VdTableSkeleton.vue";
import { useVirtualTable } from "@/components/structures/VdVirtualTable/use-virtual-table";
import VdVirtualTable from "@/components/structures/VdVirtualTable/VdVirtualTable.vue";

import type { CampaignsListPageCommonCampaignRow } from "../../types";
import type { CampaignsListPageColumns } from "../../use-columns";
import CampaignDetailsMenu from "../CampaignDetailsMenu/CampaignDetailsMenu.vue";

const props = defineProps<{
  rows: CampaignsListPageCommonCampaignRow[];
  filteredRows: CampaignsListPageCommonCampaignRow[];
  selectedColumns: CampaignsListPageColumns;
  isLoading: boolean;
}>();

const selectedColumns = computed(() => props.selectedColumns);
const { columnDefs } = useVirtualTable({ columns: selectedColumns });
</script>

<template>
  <div class="campaigns-list-table-component">
    <VdTableSkeleton v-if="isLoading" :columns="selectedColumns" />

    <slot v-else-if="rows.length === 0" name="empty" />

    <VdEmpty v-else-if="filteredRows.length === 0" title="no data">
      <p class="text-color-secondary m-0">
        No campaign matching your filters.
      </p>
    </VdEmpty>

    <VdVirtualTable
      v-else
      :data="filteredRows"
      :columns="columnDefs"
    >
      <template #header>
        <slot name="header" />
      </template>

      <template #actions="{ row }">
        <CampaignDetailsMenu
          v-if="row"
          :campaign-id="row.id"
          :campaign-status="row.status"
          :is-test-mode-campaign="row.isTestMode"
        />
      </template>
    </VdVirtualTable>
  </div>
</template>
