import { computed, ref, type WritableComputedRef } from "vue";

import { YESTERDAY_DATE } from "@/constants";
import { extractDateFromDatetime } from "@/utils/common";

const startDate = ref<Date>(new Date());
const endDate = ref<Date>(new Date());

function isYesterdayDate(date: Date): boolean {
  return extractDateFromDatetime(date) === extractDateFromDatetime(YESTERDAY_DATE);
}

export type ReportingDateRange = WritableComputedRef<{ start: Date; end: Date }>;

const state = ref({
  start: YESTERDAY_DATE,
  end: YESTERDAY_DATE,
});
export default function useReportingDateRange(): ReportingDateRange {
  startDate.value = isYesterdayDate(state.value.start) ? new Date() : state.value.start;
  endDate.value = isYesterdayDate(state.value.end) ? new Date() : state.value.end;

  state.value = {
    start: startDate.value,
    end: endDate.value,
  };

  /**
   * The writable computed that will be filled by the primevue calendar component
   */
  return computed<{ start: Date; end: Date }>({
    get() {
      return { start: startDate.value, end: endDate.value };
    },

    set(newValue) {
      const { start, end } = newValue;
      startDate.value = start;
      endDate.value = end;

      state.value = {
        start: startDate.value,
        end: endDate.value,
      };
    },
  });
}
